html, body {
  margin: 0;
  padding: 0;
}

.App {
  padding-bottom: 15px;
}

.row.column {
  transition: background-color 200ms linear, box-shadow 200ms linear !important;
}